import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/ui/breakpoints'
import { MAIN_CONTAINER_WIDTH, HEADER_HEIGHT_MOBILE } from '../constants/ui/elements'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const PageLayout = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  color: ${({ theme }) => theme.textPrimary};
  width: 100%;
  overflow-x: hidden;

  @media (max-width: ${breakpoints.screenMD}) {
    padding-top: ${HEADER_HEIGHT_MOBILE};
  }
`

export const FadeIn = styled.div`
  opacity: 0;
  will-change: opacity;
  animation: ${fadeIn} 0.4s ease-in forwards 0.2s;
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  max-width: ${({ maxWidth }) => maxWidth ?? MAIN_CONTAINER_WIDTH};
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${breakpoints.screenMD}) {
    flex-direction: ${({ columnReverse, mobileColumn }) =>
      columnReverse ? 'column-reverse' : mobileColumn ? 'column' : 'row'};
    justify-content: ${({ mobileJustifyContent }) => mobileJustifyContent};
    align-items: ${({ mobileAlignItems }) => mobileAlignItems};
    max-width: ${({ mobileMaxWidth }) => mobileMaxWidth ?? MAIN_CONTAINER_WIDTH};
  }
`

export const Square = styled.div`
  background-color: ${({ theme }) => theme.backgroundSecondary};
  padding: 3.5rem 3rem;

  @media (max-width: ${breakpoints.screenMD}) {
    padding: 2rem 1.5rem;
  }
`
