import styled from 'styled-components'

import breakpoints from '../../constants/ui/breakpoints'

export const CookiesStyled = styled.div`
  .CookieConsent {
    left: 5rem !important;
    bottom: 1.5rem !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    max-width: 440px;
    padding: 2rem 2.5rem;

    @media (max-width: ${breakpoints.screenL}) {
      left: 2rem !important;
    }

    @media (max-width: ${breakpoints.screenLG}) {
      left: 1rem !important;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      max-width: calc(100% - 2rem);
      padding: 2rem 1.5rem;
    }

    .content {
      margin: 0 !important;
    }

    .btn {
      font-size: 1rem;
      line-height: 1;
      font-weight: 500;
      color: ${({ theme }) => theme.textLight} !important;
      background: ${({ theme }) => theme.buttonPrimary} !important;
      border: 1px solid ${({ theme }) => theme.buttonPrimary} !important;
      border-radius: 1.5rem !important;
      padding: 0.875rem 2rem !important;
      margin: 1.5rem 0 0 !important;

      :hover {
        opacity: 0.8;
      }
    }
  }
`
