import React from 'react'
import CookieConsent from 'react-cookie-consent'

import { theme } from '../constants/ui/theme'

import { ParagraphXS } from '../styles/Typography'
import { CookiesStyled } from '../styles/components/CookiesStyled'
import { GatsbyLinkStyled } from '../styles/components/LinkStyled'

/**
 * The cookies banner.
 * @param {ComponentProps} props
 * @returns {FunctionComponent}
 */
const Cookies = () => (
  <CookiesStyled>
    <CookieConsent
      buttonText="Continue browsing"
      buttonClasses="btn"
      contentClasses="content"
      cookieName="gatsby-gdpr-google-analytics"
    >
      <ParagraphXS color={theme.textLight}>
        We use cookies that, through the use of statistics, help us make your experience here one of
        the best. If you don't like them, you can disable them or find out more about them in our{' '}
        <GatsbyLinkStyled to="/cookies-policy">Cookies Policy</GatsbyLinkStyled>. If you continue to
        browse this page without changing any settings, we will interpret that as your complete
        consent to the use of cookies.
      </ParagraphXS>
    </CookieConsent>
  </CookiesStyled>
)

export default Cookies
