import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

import ArrowLink from './icons/arrow-link'
import CaretRight from './icons/caret-right'
import Facebook from './icons/facebook'
import Linkedin from './icons/linkedin'
import Youtube from './icons/youtube'

import { Container } from '../styles/PageLayout'
import { ParagraphL, ParagraphS, ParagraphXS } from '../styles/Typography'
import { ButtonPrimary } from '../styles/components/ButtonStyled'
import { ContactSectionStyled, FooterStyled } from '../styles/components/FooterStyled'
import { theme } from '../constants/ui/theme'

/**
 * The main footer of the website.
 * @returns {StatelessComponent}
 */
const Footer = () => (
  <>
    <ContactSectionStyled>
      <Container mobileColumn alignItems="center" justifyContent="space-between">
        <ParagraphL color={theme.textLight} weight={700}>
          Need help with the cosmetics regulations and testing?
        </ParagraphL>
        <Link to="/contact" className="contact-btn">
          <ButtonPrimary>
            <CaretRight /> Contact us
          </ButtonPrimary>
        </Link>
      </Container>
    </ContactSectionStyled>
    <FooterStyled>
      <Container column mobileColumn>
        <div className="links">
          <div className="links-group">
            <Link to="/cosmetics-regulatory">
              <div className="links-group-title">
                <ParagraphS>EU & UK Regulatory</ParagraphS>
              </div>
            </Link>
          </div>
          <div className="links-group">
            <div className="links-group-title">
              <ParagraphS>Other Markets Regulatory</ParagraphS>
            </div>
            <Link to="/international-services/usa">
              <div className="link-item">
                <ParagraphS weight={400}>USA</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/international-services/canada">
              <div className="link-item">
                <ParagraphS weight={400}>Canada</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/international-services/china">
              <div className="link-item">
                <ParagraphS weight={400}>China</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/international-services/other-countries">
              <div className="link-item">
                <ParagraphS weight={400}>Other Countries</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
          </div>
          <div className="links-group">
            <div className="links-group-title">
              <ParagraphS>Safety Testing</ParagraphS>
            </div>
            <Link to="/cosmetics-regulatory-services/stability-compatibility-test">
              <div className="link-item">
                <ParagraphS weight={400}>
                  Stability and <br /> Compatibility Test
                </ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-regulatory-services/cosmetics-challenge-test">
              <div className="link-item">
                <ParagraphS weight={400}>Challenge Test</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-testing/microbiology-testing">
              <div className="link-item">
                <ParagraphS weight={400}>Microbiology Testing</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr">
              <div className="link-item">
                <ParagraphS weight={400}>CPSR</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-testing/safety-data-sheet-sds">
              <div className="link-item">
                <ParagraphS weight={400}>SDS</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-testing/cosmetics-patch-test">
              <div className="link-item">
                <ParagraphS weight={400}>Patch Test</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-testing/sun-protection-factor-determination-spf">
              <div className="link-item">
                <ParagraphS weight={400}>SPF</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
          </div>
          <div className="links-group">
            <div className="links-group-title">
              <ParagraphS>Efficacy testing</ParagraphS>
            </div>
            <Link to="/cosmetics-testing#hair-care">
              <div className="link-item">
                <ParagraphS weight={400}>Hair care</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-testing#skin-care">
              <div className="link-item">
                <ParagraphS weight={400}>
                  Skin care and <br /> protection
                </ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-testing#sun-protection">
              <div className="link-item">
                <ParagraphS weight={400}>Sun protection</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-testing#oral-care-make-up">
              <div className="link-item">
                <ParagraphS weight={400}>Oral care and make-up</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
          </div>
          <div className="links-group">
            <div className="links-group-title">
              <ParagraphS>Ingredients</ParagraphS>
            </div>
            <Link to="/cosmetics-testing/safety-data-sheet-sds">
              <div className="link-item">
                <ParagraphS weight={400}>SDS</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
            <Link to="/cosmetics-testing/ingredient-toxicological-profiles">
              <div className="link-item">
                <ParagraphS weight={400}>Tox profile</ParagraphS>
                <ArrowLink />
              </div>
            </Link>
          </div>
        </div>
        <div className="info">
          <div className="info-group">
            <Link to="/about-us">
              <ParagraphS>About us</ParagraphS>
            </Link>
            <a href="https://news.ceway.eu">
              <ParagraphS>News</ParagraphS>
            </a>
            <Link to="/faq-cosmetics">
              <ParagraphS>FAQ</ParagraphS>
            </Link>
            <Link to="/shop">
              <ParagraphS>Shop</ParagraphS>
            </Link>
          </div>
          <div className="social">
            <a href="https://www.facebook.com/CEway" target="_blank">
              <Facebook />
            </a>
            <a
              href="https://www.linkedin.com/company/ce-way-regulatory-consultants-ltd/"
              target="_blank"
            >
              <Linkedin />
            </a>
            <a href="https://www.youtube.com/channel/UCN9Sm6sxHL_4q0fqnUsN4CQ" target="_blank">
              <Youtube />
            </a>
          </div>
        </div>
        <div className="privacy">
          <div className="privacy-content">
            <Link to="/privacy-policy">
              <ParagraphS weight={600} className="privacy-title">
                Privacy policy
              </ParagraphS>
            </Link>
          </div>
          <div className="copyright">
            <ParagraphXS>© Copyright {moment().year()} - ceway.eu</ParagraphXS>
          </div>
        </div>
      </Container>
    </FooterStyled>
  </>
)

export default Footer
