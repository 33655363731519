import styled from 'styled-components'

import breakpoints from '../../constants/ui/breakpoints'
import { HEADER_HEIGHT_MOBILE } from '../../constants/ui/elements'

export const HeaderStyled = styled.div`
  background-color: ${({ theme }) => theme.backgroundPrimary};
  padding: 0 5rem;
  z-index: 3;

  @media (max-width: ${breakpoints.screenL}) {
    padding: 0 2rem;
  }

  @media (max-width: ${breakpoints.screenLG}) {
    padding: 0 1rem;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
  }

  .link-item {
    cursor: pointer;
  }

  .upper {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid ${({ theme }) => theme.borderPrimary};
    padding: 2rem 0 1.3rem;

    @media (max-width: ${breakpoints.screenMD}) {
      align-items: center;
      padding: 1rem;
      width: 100%;
    }

    .link-item {
      padding-bottom: 0.4rem;

      :after {
        bottom: -1.4rem;
      }
    }
  }

  .lower {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.borderPrimary};
    padding: 2rem 0 2.22rem;

    .link-item {
      :after {
        bottom: -2.3rem;
      }
    }
  }

  .logo {
    line-height: 1;
    max-width: 148px;
    margin-right: 2.5rem;

    @media (max-width: ${breakpoints.screenMD}) {
      max-width: 120px;
    }
  }

  .links {
    display: flex;
    gap: 2rem;
  }

  .link-item {
    position: relative;

    :after {
      content: '';
      position: absolute;
      left: 0;
      background-color: ${({ theme }) => theme.borderHighlight};
      height: 2px;
      width: 100%;
      will-change: transition;
      transform: scale(0);
      transform-origin: center;
      transition: transform 0.2s linear;
    }

    :hover:after,
    &.active:after {
      transform: scale(1);
    }
  }

  .link-item p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .dropdown {
    position: absolute;
    top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.08);
    border-radius: 0.5rem;
    width: 100%;
    padding: 1.5rem 1rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .dropdown-item {
    p {
      transition: color 0.3s ease;
    }

    :hover p {
      color: ${({ theme }) => theme.textPrimary};
    }
  }

  #other-markets-regulatory {
    svg {
      transform: rotate(0);
      transition: transform 0.3s ease;
    }

    :hover {
      #other-markets-regulatory-dropdown {
        visibility: visible;
        opacity: 1;
      }

      svg {
        transform: rotate(-180deg);
      }
    }

    :after {
      display: none;
    }
  }

  #safety-testing {
    .dropdown {
      min-width: 225px;
    }

    svg {
      transform: rotate(0);
      transition: transform 0.3s ease;
    }

    :hover {
      #safety-testing-dropdown {
        visibility: visible;
        opacity: 1;
      }

      svg {
        transform: rotate(-180deg);
      }
    }

    :after {
      display: none;
    }
  }

  #ingredients {
    .dropdown {
      min-width: 140px;
    }

    svg {
      transform: rotate(0);
      transition: transform 0.3s ease;
    }

    :hover {
      #ingredients-dropdown {
        visibility: visible;
        opacity: 1;
      }

      svg {
        transform: rotate(-180deg);
      }
    }

    :after {
      display: none;
    }
  }

  .contact-btn {
    margin-left: auto;
  }

  .menu-btn {
    position: relative;
    background-color: transparent;
    border: 0;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;

    .menu {
      position: absolute;
      top: 0;
      left: 0;
      clip-path: inset(0px 0px 0px);
      transition: clip-path 0.3s linear;

      &.open {
        clip-path: inset(0 100% 0);
      }
    }

    .close {
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0);
      transition: transform 0.3s linear;

      &.open {
        transform: scale(1);
      }
    }
  }
`

export const MobileNav = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  transform: ${({ isOpen }) => (isOpen ? `translateY(0)` : `translateY(-100%)`)};
  will-change: transform;
  transition: transform 0.3s linear;
  padding: ${HEADER_HEIGHT_MOBILE} 0 3rem;
  overflow-y: auto;
  z-index: 2;

  @media (max-width: ${breakpoints.screenMD}) {
    display: flex;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    padding: ${HEADER_HEIGHT_MOBILE} 0 1.5rem;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
    padding: 2.5rem 1rem;
    width: 100%;

    &.border-bottom {
      border-bottom: 1px solid ${({ theme }) => theme.borderPrimary};
    }
  }

  .link-item p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;

    .icon {
      transform: rotate(0);
      transition: 0.3s ease;

      &.rotate {
        transform: rotate(-180deg);
      }
    }
  }

  .dropdown-item p {
    margin-top: 1.25rem;
  }

  .dropdown {
    width: 100%;
    height: 0;
    overflow: hidden;
    will-change: height;
    transition: height 0.3s ease-in;

    &.dropdown-1.open {
      height: 180px;
    }

    &.dropdown-2.open {
      height: 310px;
    }

    &.dropdown-3.open {
      height: 100px;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
    padding: 0 1rem;
  }
`
