export const colors = {
  black: '#000',
  white: '#fff',
  blue_400: '#0091ca',
  blue_300: '#c6efff',
  blue_200: '#ecfbff',
  blue_100: '#e9eff6',
  grey_500: '#838383',
  grey_400: '#c6c6c6',
  grey_100: '#fafafa',
  red_500: '#ff0000',
  red_400: '#fcafaf',
  green_500: '#47b54b',
  green_400: '#c4e5c6',
  yellow_500: '#edbb01',
  yellow_400: '#f6e7af',
  transparent: 'transparent',
}

export const theme = {
  // font
  fontPrimary: 'Poppins',
  fontSecondary: 'Inter',
  // background
  backgroundPrimary: colors.white,
  backgroundSecondary: colors.blue_200,
  backgroundTertiary: colors.blue_300,
  backgroundLight: colors.grey_100,
  backgroundDark: colors.black,
  backgroundHighlight: colors.blue_400,
  backgroundPositive: colors.green_400,
  backgroundWarning: colors.yellow_400,
  backgroundError: colors.red_400,
  // text
  textPrimary: colors.black,
  textSecondary: colors.grey_500,
  textHighlight: colors.blue_400,
  textLight: colors.white,
  textError: colors.red_500,
  // button
  buttonPrimary: colors.blue_400,
  buttonOutline: colors.black,
  // border
  borderPrimary: colors.blue_100,
  borderSecondary: colors.grey_400,
  borderHighlight: colors.blue_400,
  borderPositive: colors.green_500,
  borderWarning: colors.yellow_500,
  borderError: colors.red_500,
  //table
  tableHead: colors.blue_400,
  tableRow: colors.white,
  tableRowEven: colors.blue_200,
}
