import styled from 'styled-components'

import breakpoints from '../constants/ui/breakpoints'

export const Headline = styled.h1`
  font-size: 6rem; //96
  line-height: 1.1;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontPrimary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 4rem; //64
  }

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 2rem; //32
  }
`

export const TitleL = styled.h2`
  font-size: 4.5rem; //72
  line-height: 1.1;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontPrimary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 3rem; //48
  }

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 2rem; //32
  }
`

export const TitleM = styled.h2`
  font-size: 3.75rem; //60
  line-height: 1.1;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontPrimary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 3rem; //48
  }

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 2rem; //32
  }
`

export const SubtitleL = styled.h3`
  font-size: 3rem; //48
  line-height: 1.1;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontPrimary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 2rem; //32
  }
`

export const SubtitleM = styled.h4`
  font-size: 2.25rem; //36
  line-height: 1.2;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontPrimary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 2rem; //32
  }
`

export const SubtitleS = styled.h4`
  font-size: 1.875rem; //30
  line-height: 1.2;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontPrimary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};
`

export const SubtitleXS = styled.p`
  font-size: 1.5rem; //24
  line-height: 1.3;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontPrimary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 1rem; //16
    line-height: 1.5;
  }
`

export const ParagraphL = styled.p`
  font-size: 1.25rem; //20
  line-height: 1.3;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontSecondary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 1rem; //16
    line-height: 1.5;
  }
`

export const ParagraphM = styled.p`
  font-size: 1.125rem; //18
  line-height: 1.8;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontSecondary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 0.875rem; //14
  }
`

export const ParagraphS = styled.p`
  font-size: 1rem; //16
  line-height: 1.5;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontSecondary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 0.875rem; //14
  }
`

export const ParagraphXS = styled.p`
  font-size: 0.875rem; //14
  line-height: 1.5;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontSecondary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};
`

export const ParagraphXXS = styled.p`
  font-size: 0.75rem; //12
  line-height: 1.5;
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.fontSecondary};
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};
`
