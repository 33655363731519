import React from 'react'

import { theme } from '../../constants/ui/theme'

const Menu = ({ background = theme.textPrimary, className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.75 18.0002H15.25C15.6642 18.0002 16 18.336 16 18.7502C16 19.1299 15.7178 19.4437 15.3518 19.4933L15.25 19.5002H2.75C2.33579 19.5002 2 19.1644 2 18.7502C2 18.3705 2.28215 18.0567 2.64823 18.007L2.75 18.0002H15.25H2.75ZM2.75 11.5002H21.25C21.6642 11.5002 22 11.836 22 12.2502C22 12.6299 21.7178 12.9437 21.3518 12.9933L21.25 13.0002H2.75C2.33579 13.0002 2 12.6644 2 12.2502C2 11.8705 2.28215 11.5567 2.64823 11.507L2.75 11.5002H21.25H2.75ZM2.75 5.00342H18.25C18.6642 5.00342 19 5.3392 19 5.75342C19 6.13311 18.7178 6.44691 18.3518 6.49657L18.25 6.50342H2.75C2.33579 6.50342 2 6.16763 2 5.75342C2 5.37372 2.28215 5.05993 2.64823 5.01026L2.75 5.00342H18.25H2.75Z"
      fill={background}
    />
  </svg>
)

export default Menu
