import styled from 'styled-components'
import { Link } from 'gatsby'

const LinkStyled = styled.a`
  font-size: inherit;
  font-weight: 700;
  color: ${({ theme }) => theme.textHighlight};
  border-bottom: 1px solid ${({ theme }) => theme.textHighlight};
  padding-bottom: 1px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`

const GatsbyLinkStyled = styled(Link)`
  font-size: inherit;
  font-weight: 700;
  color: ${({ theme }) => theme.textHighlight};
  border-bottom: 1px solid ${({ theme }) => theme.textHighlight};
  padding-bottom: 1px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`

export { LinkStyled, GatsbyLinkStyled }
