import React from 'react'

const Logo = () => (
  <svg width="112" height="48" viewBox="0 0 112 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.1263 39.2737L27.5423 46.7297C26.7141 47.5431 25.5911 48 24.4201 48C23.2491 48 22.126 47.5431 21.2979 46.7297L1.29455 27.0688C0.884177 26.6664 0.558609 26.1884 0.336475 25.6622C0.11434 25.136 0 24.5719 0 24.0022C0 23.4325 0.11434 22.8684 0.336475 22.3422C0.558609 21.816 0.884177 21.338 1.29455 20.9355L6.85935 15.4678C6.85935 15.5232 6.85935 15.5744 6.85935 15.6299V23.9499C6.85906 24.844 7.03808 25.7293 7.38616 26.5554C7.73424 27.3814 8.24456 28.132 8.88797 28.7641C9.53138 29.3963 10.2953 29.8978 11.136 30.2398C11.9767 30.5818 12.8777 30.7577 13.7877 30.7574H17.5656V32.4641C17.5656 33.358 17.7448 34.2432 18.093 35.0692C18.4411 35.8951 18.9515 36.6455 19.5948 37.2777C20.2382 37.9098 21.002 38.4112 21.8425 38.7533C22.6831 39.0954 23.584 39.2715 24.4939 39.2715L35.1263 39.2737Z"
      fill="black"
    />
    <path
      d="M47.5477 27.0667L40.348 34.1429H24.5177C24.0571 34.1429 23.6153 33.9631 23.2895 33.6431C22.9638 33.323 22.7808 32.8889 22.7808 32.4363V30.7595H37.4864V25.6331H22.7851V24.1653C22.7851 23.7127 22.9681 23.2786 23.2939 22.9585C23.6196 22.6385 24.0614 22.4587 24.5221 22.4587H42.0871V17.3323H24.5004C22.6629 17.3323 20.9006 18.0495 19.6013 19.3261C18.302 20.6028 17.5721 22.3343 17.5721 24.1397V25.6331H13.8202C13.3595 25.6331 12.9177 25.4533 12.592 25.1332C12.2663 24.8131 12.0833 24.379 12.0833 23.9264V15.6491C12.0833 15.1964 12.2663 14.7623 12.592 14.4423C12.9177 14.1222 13.3595 13.9424 13.8202 13.9424H31.3874V8.82025H13.8007C13.7442 8.82025 13.6921 8.82025 13.6357 8.82025L21.3152 1.27038C22.1434 0.456953 23.2664 0 24.4374 0C25.6084 0 26.7314 0.456953 27.5596 1.27038L47.5651 20.9269C47.9755 21.3294 48.301 21.8074 48.5232 22.3336C48.7453 22.8598 48.8596 23.4239 48.8596 23.9936C48.8596 24.5633 48.7453 25.1274 48.5232 25.6536C48.301 26.1798 47.9755 26.6578 47.5651 27.0603"
      fill="#0091CA"
    />
    <path d="M48.8419 34.1431H43.6201V39.2737H48.8419V34.1431Z" fill="black" />
    <path
      d="M88.944 35.7387H83.8981C82.2588 35.7387 80.6261 35.6918 80.6261 33.9297C80.6261 32.4214 82.6171 32.3041 83.8829 32.3041L86.7576 32.2934H88.944V35.7387ZM86.0215 22.4097H77.0914V25.8998L83.8221 25.9254C85.6329 25.9254 88.9093 25.9019 88.9093 28.8779L82.9232 28.9099C80.2288 28.9099 75.958 29.2577 75.958 33.7377C75.958 38.9729 79.4558 39.2694 82.971 39.2694H93.4384V28.7478C93.4384 24.5302 91.9403 22.4118 86.0281 22.4118"
      fill="#0091CA"
    />
    <path
      d="M73.0095 22.5142V39.2054H59.1985C54.9082 39.2054 52.7522 37.5755 52.7522 31.7686V22.5376H57.0946L57.1207 30.7296C57.1207 32.511 56.8189 35.728 60.2819 35.728H60.688V22.5142H65.0825V35.728H68.5564V22.5142H73.0095Z"
      fill="#0091CA"
    />
    <path
      d="M112 22.4502V39.2331C112 43.3569 110.515 45.4774 105.243 45.4774H96.7323V41.9382L104.442 41.9574C106.757 41.9574 107.508 41.8465 107.508 39.2801H103.153C97.8787 39.2801 96.3958 37.1467 96.3958 33.0123V22.4502H100.868L100.89 33.2385C100.89 35.103 101.717 35.7622 103.211 35.7366L107.512 35.7195L107.53 22.4502H112Z"
      fill="#0091CA"
    />
  </svg>
)

export default Logo
