import styled from 'styled-components'

import breakpoints from '../../constants/ui/breakpoints'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  padding: 0.875rem 2rem;
  border-radius: 1.5rem;
  border: 1px solid;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.6' : '1')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  :hover {
    opacity: ${({ isDisabled }) => (isDisabled ? '0.6' : '0.8')};
  }

  @media (max-width: ${breakpoints.screenSM}) {
    min-width: 100%;
  }
`

export const ButtonPrimary = styled(Button)`
  background-color: ${({ theme }) => theme.buttonPrimary};
  color: ${({ theme }) => theme.textLight};
  border-color: ${({ theme }) => theme.buttonPrimary};
`

export const ButtonOutline = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.textPrimary};
  border-color: ${({ theme }) => theme.buttonOutline};
  border-radius: 100%;
  width: 4.5rem;
  height: 4.5rem;
  padding: 0;
  will-change: background-color, border-color;
  transition: all 0.3s ease-in;

  @media (max-width: ${breakpoints.screenSM}) {
    width: 3.5rem;
    height: 3.5rem;
  }

  svg path {
    will-change: fill;
    transition: fill 0.3s ease-in;
  }

  :hover {
    opacity: 1;
    background-color: ${({ isDisabled, theme }) =>
      isDisabled ? 'transparent' : theme.buttonPrimary};
    border-color: ${({ isDisabled, theme }) =>
      isDisabled ? theme.buttonOutline : theme.buttonPrimary};

    svg path {
      fill: ${({ isDisabled, theme }) => (isDisabled ? theme.textPrimary : theme.textLight)};
    }
  }
`
