import React, { useState } from 'react'
import { Link } from 'gatsby'

import { theme } from '../constants/ui/theme'

import Logo from './icons/logo'
import CaretRight from './icons/caret-right'
import CaretDown from './icons/caret-down'
import Close from './icons/close'
import Menu from './icons/menu'

import { Container } from '../styles/PageLayout'
import { ParagraphL, ParagraphS, ParagraphXS } from '../styles/Typography'
import { HeaderStyled, MobileNav } from '../styles/components/HeaderStyled'
import { ButtonPrimary } from '../styles/components/ButtonStyled'

/**
 * The main header of the website.
 * @returns {StatelessComponent}
 */
const Header = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [openRegulatoryDropdown, setOpenRegulatoryDropdown] = useState(false)
  const [openSafetyDropdown, setOpenSafetyDropdown] = useState(false)
  const [openIngredientsDropdown, setOpenIngredientsDropdown] = useState(false)

  const toggleMobileNav = () => {
    document.body.classList.toggle('no-scroll')
    setIsMobileNavOpen(!isMobileNavOpen)
  }

  const closeMobileNav = () => {
    document.body.classList.toggle('no-scroll')
    setIsMobileNavOpen(false)
  }

  return (
    <>
      <HeaderStyled isOpen={isMobileNavOpen}>
        <Container column>
          <div className="upper">
            <Link to="/">
              <div className="logo">
                <Logo />
              </div>
            </Link>
            <div className="links hide-sm">
              <Link to="/about-us" className="link-item">
                <ParagraphS weight={500}>About us</ParagraphS>
              </Link>
              <a href="https://news.ceway.eu" className="link-item">
                <ParagraphS weight={500}>News</ParagraphS>
              </a>
              <a href="https://news.ceway.eu/regulatory-updates" className="link-item">
                <ParagraphS weight={500}>Regulatory updates</ParagraphS>
              </a>
              <Link to="/faq-cosmetics" className="link-item">
                <ParagraphS weight={500}>FAQ</ParagraphS>
              </Link>
              <Link to="/shop" className="link-item">
                <ParagraphS weight={500}>Shop</ParagraphS>
              </Link>
            </div>
            <Link to="/contact" className="contact-btn hide-sm">
              <ButtonPrimary>
                <CaretRight /> Contact us
              </ButtonPrimary>
            </Link>
            <button className="menu-btn hide-lg" onClick={toggleMobileNav}>
              <Menu className={`menu ${isMobileNavOpen ? 'open' : ''}`} />
              <Close className={`close ${isMobileNavOpen ? 'open' : ''}`} />
            </button>
          </div>

          <div className="lower hide-sm">
            <div className="links">
              <Link to="/cosmetics-regulatory" className="link-item">
                <ParagraphS weight={500}>EU & UK Regulatory</ParagraphS>
              </Link>
              <div className="link-item" id="other-markets-regulatory">
                <ParagraphS weight={500}>
                  Other Markets Regulatory <CaretDown />
                </ParagraphS>
                <div className="dropdown" id="other-markets-regulatory-dropdown">
                  <Link to="/international-services/usa" className="dropdown-item">
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      USA
                    </ParagraphXS>
                  </Link>
                  <Link to="/international-services/canada" className="dropdown-item">
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      Canada
                    </ParagraphXS>
                  </Link>
                  <Link to="/international-services/china" className="dropdown-item">
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      China
                    </ParagraphXS>
                  </Link>
                  <Link to="/international-services/other-countries" className="dropdown-item">
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      Other Countries
                    </ParagraphXS>
                  </Link>
                </div>
              </div>
              <div className="link-item" id="safety-testing">
                <ParagraphS weight={500}>
                  Safety Testing <CaretDown />
                </ParagraphS>
                <div className="dropdown" id="safety-testing-dropdown">
                  <Link
                    to="/cosmetics-regulatory-services/stability-compatibility-test"
                    className="dropdown-item"
                  >
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      Stability and Compatibility
                    </ParagraphXS>
                  </Link>
                  <Link
                    to="/cosmetics-regulatory-services/cosmetics-challenge-test"
                    className="dropdown-item"
                  >
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      Challenge Test
                    </ParagraphXS>
                  </Link>
                  <Link to="/cosmetics-testing/microbiology-testing" className="dropdown-item">
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      Microbiology Testing
                    </ParagraphXS>
                  </Link>
                  <Link
                    to="/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr"
                    className="dropdown-item"
                  >
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      CPSR
                    </ParagraphXS>
                  </Link>
                  <Link to="/cosmetics-testing/safety-data-sheet-sds" className="dropdown-item">
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      SDS
                    </ParagraphXS>
                  </Link>
                  <Link to="/cosmetics-testing/cosmetics-patch-test" className="dropdown-item">
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      Patch Test
                    </ParagraphXS>
                  </Link>
                  <Link
                    to="/cosmetics-testing/sun-protection-factor-determination-spf"
                    className="dropdown-item"
                  >
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      SPF
                    </ParagraphXS>
                  </Link>
                </div>
              </div>
              <Link to="/cosmetics-testing" className="link-item">
                <ParagraphS weight={500}>Efficacy testing</ParagraphS>
              </Link>
              <div className="link-item" id="ingredients">
                <ParagraphS weight={500}>
                  Ingredients <CaretDown />
                </ParagraphS>
                <div className="dropdown" id="ingredients-dropdown">
                  <Link to="/cosmetics-testing/safety-data-sheet-sds" className="dropdown-item">
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      SDS
                    </ParagraphXS>
                  </Link>
                  <Link
                    to="/cosmetics-testing/ingredient-toxicological-profiles"
                    className="dropdown-item"
                  >
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      Tox profile
                    </ParagraphXS>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </HeaderStyled>

      <MobileNav isOpen={isMobileNavOpen}>
        <div className="links border-bottom">
          <Link to="/cosmetics-regulatory" className="link-item" onClick={closeMobileNav}>
            <ParagraphL weight={500}>EU & UK Regulatory</ParagraphL>
          </Link>
          <div
            className="link-item"
            onClick={() => setOpenRegulatoryDropdown(!openRegulatoryDropdown)}
          >
            <ParagraphL weight={500}>
              Other Markets Regulatory{' '}
              <CaretDown className={`icon ${openRegulatoryDropdown ? 'rotate' : ''}`} />
            </ParagraphL>
            <div className={`dropdown dropdown-1 ${openRegulatoryDropdown ? 'open' : ''}`}>
              <Link
                to="/international-services/usa"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  USA
                </ParagraphL>
              </Link>
              <Link
                to="/international-services/canada"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  Canada
                </ParagraphL>
              </Link>
              <Link
                to="/international-services/china"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  China
                </ParagraphL>
              </Link>
              <Link
                to="/international-services/other-countries"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  Other Countries
                </ParagraphL>
              </Link>
            </div>
          </div>
          <div className="link-item" onClick={() => setOpenSafetyDropdown(!openSafetyDropdown)}>
            <ParagraphL weight={500}>
              Safety Testing <CaretDown className={`icon ${openSafetyDropdown ? 'rotate' : ''}`} />
            </ParagraphL>
            <div className={`dropdown dropdown-2 ${openSafetyDropdown ? 'open' : ''}`}>
              <Link
                to="/cosmetics-regulatory-services/stability-compatibility-test"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  Stability and Compatibility Test
                </ParagraphL>
              </Link>
              <Link
                to="/cosmetics-regulatory-services/cosmetics-challenge-test"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  Challenge Test
                </ParagraphL>
              </Link>
              <Link
                to="/cosmetics-testing/microbiology-testing"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  Microbiology Testing
                </ParagraphL>
              </Link>
              <Link
                to="/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  CPSR
                </ParagraphL>
              </Link>
              <Link
                to="/cosmetics-testing/safety-data-sheet-sds"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  SDS
                </ParagraphL>
              </Link>
              <Link
                to="/cosmetics-testing/cosmetics-patch-test"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  Patch Test
                </ParagraphL>
              </Link>
              <Link
                to="/cosmetics-testing/sun-protection-factor-determination-spf"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  SPF
                </ParagraphL>
              </Link>
            </div>
          </div>
          <Link to="/cosmetics-testing" className="link-item" onClick={closeMobileNav}>
            <ParagraphL weight={500}>Efficacy testing</ParagraphL>
          </Link>
          <div
            className="link-item"
            onClick={() => setOpenIngredientsDropdown(!openIngredientsDropdown)}
          >
            <ParagraphL weight={500}>
              Ingredients{' '}
              <CaretDown className={`icon ${openIngredientsDropdown ? 'rotate' : ''}`} />
            </ParagraphL>
            <div className={`dropdown dropdown-3 ${openIngredientsDropdown ? 'open' : ''}`}>
              <Link
                to="/cosmetics-testing/safety-data-sheet-sds"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenIngredientsDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  SDS
                </ParagraphL>
              </Link>
              <Link
                to="/cosmetics-testing/ingredient-toxicological-profiles"
                className="dropdown-item"
                onClick={() => {
                  closeMobileNav()
                  setOpenIngredientsDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  Tox profile
                </ParagraphL>
              </Link>
            </div>
          </div>
        </div>
        <div className="links border-bottom">
          <Link to="/about-us" className="link-item" onClick={closeMobileNav}>
            <ParagraphL weight={500}>About us</ParagraphL>
          </Link>
          <a href="https://news.ceway.eu" className="link-item" onClick={closeMobileNav}>
            <ParagraphL weight={500}>News</ParagraphL>
          </a>
          <a href="https://news.ceway.eu/regulatory-updates" className="link-item">
            <ParagraphS weight={500}>Regulatory updates</ParagraphS>
          </a>
          <Link to="/faq-cosmetics" className="link-item" onClick={closeMobileNav}>
            <ParagraphL weight={500}>FAQ</ParagraphL>
          </Link>
          <Link to="/shop" className="link-item" onClick={closeMobileNav}>
            <ParagraphL weight={500}>Shop</ParagraphL>
          </Link>
        </div>
        <div className="btn">
          <Link to="/contact">
            <ButtonPrimary onClick={closeMobileNav}>
              <CaretRight /> Contact us
            </ButtonPrimary>
          </Link>
        </div>
      </MobileNav>
    </>
  )
}

export default Header
