import React from 'react'

import { theme } from '../../constants/ui/theme'

const Youtube = ({ background = theme.textHighlight, className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.7609 7.20078C23.7609 7.20078 23.5266 5.54609 22.8047 4.81953C21.8906 3.86328 20.8688 3.85859 20.4 3.80234C17.0438 3.55859 12.0047 3.55859 12.0047 3.55859H11.9953C11.9953 3.55859 6.95625 3.55859 3.6 3.80234C3.13125 3.85859 2.10938 3.86328 1.19531 4.81953C0.473438 5.54609 0.24375 7.20078 0.24375 7.20078C0.24375 7.20078 0 9.14609 0 11.0867V12.9055C0 14.8461 0.239062 16.7914 0.239062 16.7914C0.239062 16.7914 0.473437 18.4461 1.19062 19.1727C2.10469 20.1289 3.30469 20.0961 3.83906 20.1992C5.76094 20.382 12 20.4383 12 20.4383C12 20.4383 17.0438 20.4289 20.4 20.1898C20.8688 20.1336 21.8906 20.1289 22.8047 19.1727C23.5266 18.4461 23.7609 16.7914 23.7609 16.7914C23.7609 16.7914 24 14.8508 24 12.9055V11.0867C24 9.14609 23.7609 7.20078 23.7609 7.20078ZM9.52031 15.1133V8.36797L16.0031 11.7523L9.52031 15.1133Z"
      fill={background}
    />
  </svg>
)

export default Youtube
