exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-cosmetics-regulatory-js": () => import("./../../../src/pages/cosmetics-regulatory.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-challenge-test-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-challenge-test.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-challenge-test-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-product-information-file-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-product-information-file.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-product-information-file-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-responsible-person-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-responsible-person.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-responsible-person-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-safety-assessment-cpsr-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-safety-assessment-cpsr-js" */),
  "component---src-pages-cosmetics-regulatory-services-cpnp-notification-scpn-notification-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cpnp-notification-scpn-notification.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cpnp-notification-scpn-notification-js" */),
  "component---src-pages-cosmetics-regulatory-services-product-classification-js": () => import("./../../../src/pages/cosmetics-regulatory-services/product-classification.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-product-classification-js" */),
  "component---src-pages-cosmetics-regulatory-services-product-labelling-and-claims-js": () => import("./../../../src/pages/cosmetics-regulatory-services/product-labelling-and-claims.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-product-labelling-and-claims-js" */),
  "component---src-pages-cosmetics-regulatory-services-stability-compatibility-test-js": () => import("./../../../src/pages/cosmetics-regulatory-services/stability-compatibility-test.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-stability-compatibility-test-js" */),
  "component---src-pages-cosmetics-testing-anti-aging-js": () => import("./../../../src/pages/cosmetics-testing/anti-aging.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-aging-js" */),
  "component---src-pages-cosmetics-testing-anti-dandruff-js": () => import("./../../../src/pages/cosmetics-testing/anti-dandruff.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-dandruff-js" */),
  "component---src-pages-cosmetics-testing-anti-wrinkle-js": () => import("./../../../src/pages/cosmetics-testing/anti-wrinkle.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-wrinkle-js" */),
  "component---src-pages-cosmetics-testing-brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease-js": () => import("./../../../src/pages/cosmetics-testing/brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease-js" */),
  "component---src-pages-cosmetics-testing-cellulite-reduction-anti-stretch-marks-js": () => import("./../../../src/pages/cosmetics-testing/cellulite-reduction-anti-stretch-marks.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cellulite-reduction-anti-stretch-marks-js" */),
  "component---src-pages-cosmetics-testing-cleansing-js": () => import("./../../../src/pages/cosmetics-testing/cleansing.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cleansing-js" */),
  "component---src-pages-cosmetics-testing-cosmetics-patch-test-js": () => import("./../../../src/pages/cosmetics-testing/cosmetics-patch-test.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cosmetics-patch-test-js" */),
  "component---src-pages-cosmetics-testing-eye-puffiness-dark-circles-js": () => import("./../../../src/pages/cosmetics-testing/eye-puffiness-dark-circles.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-eye-puffiness-dark-circles-js" */),
  "component---src-pages-cosmetics-testing-hair-density-increase-volumizing-effect-js": () => import("./../../../src/pages/cosmetics-testing/hair-density-increase-volumizing-effect.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-density-increase-volumizing-effect-js" */),
  "component---src-pages-cosmetics-testing-hair-growth-anti-hair-loss-js": () => import("./../../../src/pages/cosmetics-testing/hair-growth-anti-hair-loss.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-growth-anti-hair-loss-js" */),
  "component---src-pages-cosmetics-testing-hair-thickness-increase-js": () => import("./../../../src/pages/cosmetics-testing/hair-thickness-increase.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-thickness-increase-js" */),
  "component---src-pages-cosmetics-testing-ingredient-toxicological-profiles-js": () => import("./../../../src/pages/cosmetics-testing/ingredient-toxicological-profiles.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-ingredient-toxicological-profiles-js" */),
  "component---src-pages-cosmetics-testing-js": () => import("./../../../src/pages/cosmetics-testing.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-js" */),
  "component---src-pages-cosmetics-testing-microbiology-testing-js": () => import("./../../../src/pages/cosmetics-testing/microbiology-testing.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-microbiology-testing-js" */),
  "component---src-pages-cosmetics-testing-moisturizing-hydration-js": () => import("./../../../src/pages/cosmetics-testing/moisturizing-hydration.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-moisturizing-hydration-js" */),
  "component---src-pages-cosmetics-testing-pore-size-reduction-js": () => import("./../../../src/pages/cosmetics-testing/pore-size-reduction.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-pore-size-reduction-js" */),
  "component---src-pages-cosmetics-testing-redness-reduction-js": () => import("./../../../src/pages/cosmetics-testing/redness-reduction.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-redness-reduction-js" */),
  "component---src-pages-cosmetics-testing-rejuvenating-firming-lifting-js": () => import("./../../../src/pages/cosmetics-testing/rejuvenating-firming-lifting.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-rejuvenating-firming-lifting-js" */),
  "component---src-pages-cosmetics-testing-safety-data-sheet-sds-js": () => import("./../../../src/pages/cosmetics-testing/safety-data-sheet-sds.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-safety-data-sheet-sds-js" */),
  "component---src-pages-cosmetics-testing-sebum-regulating-mattifying-effect-js": () => import("./../../../src/pages/cosmetics-testing/sebum-regulating-mattifying-effect.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-sebum-regulating-mattifying-effect-js" */),
  "component---src-pages-cosmetics-testing-self-tanning-products-js": () => import("./../../../src/pages/cosmetics-testing/self-tanning-products.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-self-tanning-products-js" */),
  "component---src-pages-cosmetics-testing-skin-barrier-improving-js": () => import("./../../../src/pages/cosmetics-testing/skin-barrier-improving.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-skin-barrier-improving-js" */),
  "component---src-pages-cosmetics-testing-skin-sensitivity-evaluation-anti-irritating-js": () => import("./../../../src/pages/cosmetics-testing/skin-sensitivity-evaluation-anti-irritating.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-skin-sensitivity-evaluation-anti-irritating-js" */),
  "component---src-pages-cosmetics-testing-smoothing-js": () => import("./../../../src/pages/cosmetics-testing/smoothing.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-smoothing-js" */),
  "component---src-pages-cosmetics-testing-split-ends-repairing-js": () => import("./../../../src/pages/cosmetics-testing/split-ends-repairing.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-split-ends-repairing-js" */),
  "component---src-pages-cosmetics-testing-sun-protection-factor-determination-spf-js": () => import("./../../../src/pages/cosmetics-testing/sun-protection-factor-determination-spf.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-sun-protection-factor-determination-spf-js" */),
  "component---src-pages-cosmetics-testing-teeth-whitening-js": () => import("./../../../src/pages/cosmetics-testing/teeth-whitening.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-teeth-whitening-js" */),
  "component---src-pages-eu-cosmetic-compliance-checklist-js": () => import("./../../../src/pages/eu-cosmetic-compliance-checklist.js" /* webpackChunkName: "component---src-pages-eu-cosmetic-compliance-checklist-js" */),
  "component---src-pages-faq-cosmetics-js": () => import("./../../../src/pages/faq-cosmetics.js" /* webpackChunkName: "component---src-pages-faq-cosmetics-js" */),
  "component---src-pages-formula-compliance-js": () => import("./../../../src/pages/formula-compliance.js" /* webpackChunkName: "component---src-pages-formula-compliance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-services-canada-js": () => import("./../../../src/pages/international-services/canada.js" /* webpackChunkName: "component---src-pages-international-services-canada-js" */),
  "component---src-pages-international-services-china-js": () => import("./../../../src/pages/international-services/china.js" /* webpackChunkName: "component---src-pages-international-services-china-js" */),
  "component---src-pages-international-services-other-countries-js": () => import("./../../../src/pages/international-services/other-countries.js" /* webpackChunkName: "component---src-pages-international-services-other-countries-js" */),
  "component---src-pages-international-services-usa-js": () => import("./../../../src/pages/international-services/usa.js" /* webpackChunkName: "component---src-pages-international-services-usa-js" */),
  "component---src-pages-pi-finstructions-js": () => import("./../../../src/pages/PIFinstructions.js" /* webpackChunkName: "component---src-pages-pi-finstructions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-us-cosmetics-regulation-mocra-js": () => import("./../../../src/pages/us-cosmetics-regulation-mocra.js" /* webpackChunkName: "component---src-pages-us-cosmetics-regulation-mocra-js" */)
}

