import styled from 'styled-components'

import breakpoints from '../../constants/ui/breakpoints'

const ContactSectionStyled = styled.div`
  background-color: ${({ theme }) => theme.backgroundDark};
  padding: 1.5rem 5rem;

  @media (max-width: ${breakpoints.screenL}) {
    padding: 1.5rem 2rem;
  }

  @media (max-width: ${breakpoints.screenLG}) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    .contact-btn {
      margin-top: 1.5rem;
    }
  }
`

const FooterStyled = styled.div`
  padding: 5rem 5rem 3rem;

  @media (max-width: ${breakpoints.screenL}) {
    padding: 4rem 2rem;
  }

  @media (max-width: ${breakpoints.screenLG}) {
    padding: 3rem 1rem;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    padding: 3rem 0;
  }

  .links {
    display: flex;
    gap: 2rem;
    padding-bottom: 3rem;

    @media (max-width: ${breakpoints.screenMD}) {
      flex-direction: column;
    }
  }

  .links-group {
    width: 20%;

    @media (max-width: ${breakpoints.screenMD}) {
      width: 100%;
    }
  }

  .links-group-title {
    border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    @media (max-width: ${breakpoints.screenMD}) {
      text-align: center;
    }
  }

  .link-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media (max-width: ${breakpoints.screenMD}) {
      max-width: 80%;
      margin: 0 auto 1.5rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      max-width: 100%;
      padding: 0 1rem;
    }

    br {
      @media (max-width: ${breakpoints.screenMD}) {
        display: none;
      }
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
    padding-bottom: 2rem;

    @media (max-width: ${breakpoints.screenMD}) {
      flex-direction: column;
      gap: 3rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 0 1rem 2rem;
    }
  }

  .info-group {
    display: flex;
    gap: 2rem;
  }

  .privacy {
    display: flex;
    justify-content: space-between;
    padding-top: 3.5rem;

    @media (max-width: ${breakpoints.screenMD}) {
      flex-direction: column;
      align-items: center;
      gap: 3rem;
      text-align: center;
      padding-top: 2rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 3rem 1rem 0;
    }
  }

  .privacy-content {
    width: 100%;
    max-width: 610px;
  }

  .social {
    display: flex;
    gap: 2.5rem;
  }
`

export { ContactSectionStyled, FooterStyled }
